import * as React from 'react'

import Layout from 'components/Layout/Layout'
import Seo from 'components/Layout/Seo'
import styled from 'styled-components'
import { Section } from '../components/Layout'
import { LinkButton } from '../components/Shared/Buttons'

const Container = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" pathname="404" />
    <Section>
      <Container>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <LinkButton to="/" label="Go Back to Main Page" />
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
